@import '../scss/variables';

.root {
  align-items: flex-start;
  column-gap: 4px;
  display: flex;
  list-style: none;
  padding: 24px 16px;
  position: relative;
  min-height: 282px;
  justify-content: flex-start;

  @media (min-width: $view-lg) {
    min-height: auto;
    padding: 15px 24px;
  }
}
