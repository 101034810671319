@import '../scss/colours';
@import '../scss/helpers';
@import '../scss/variables';

.menu-item-expandable {
  position: relative;
}

.button {
  appearance: none;
  background-color: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  display: flex;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.045em;
  line-height: 24px;
  margin: 0;
  padding: 8px 24px 8px 0;
  position: relative;
  text-align: left;
  transition: color  300ms ease-in-out;
  width: 100%;
  z-index: 1;

  @media (min-width: $view-lg) {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.35px;
    line-height: 14px; /* 100% */
    margin: 0;
    padding: 12px 24px 12px 2px;
    transition: all 0.3s ease-in-out;

    [data-visual-mode='dark'][data-direction='top'] .expanded & {
      color: var(--white);
    }
  }
}

.arrow {
  position: absolute;
  right: 0;
  top: 8px;
  transition: transform  300ms ease-in-out;

  @media (min-width: $view-lg) {
    top: 7px;
  }

  .expanded & {
    transform: rotate(-180deg);
  }
}

.collapse {
  padding: 0;
  transition: max-height 0.1s linear;
  z-index: 3;
  left: 0;
  top: 8px;
  width: 100%;

  @media (min-width: $view-lg) {
    background-color: var(--white);
    overflow: visible;
    position: absolute;
    left: 16px;
    top: 50px;
    z-index: 4;
    min-height: auto;
  }

  .expanded & {
    display: block;
    width: 100%;
    padding: 0;
    left: 0;
    padding: 0;
  
    @media (min-width: $view-lg) {
      min-height: auto;
      width: auto;
    }
  }
}

.container {
  flex-direction: column;
  width: 100%;

  @media (min-width: $view-lg) {
    background-color: var(--white);
    flex-direction: row;
    height: auto;
    width: 408px;
  }
}

.menu {
  box-sizing: border-box;
  width: 100%;

  @media (min-width: $view-lg) {
    border-top: 1px solid var(--grey-mid);
    display: flex;
    padding: 0;
    flex-direction: column;
    box-shadow: 0px 1.2037px 3.14815px 0px rgba(0, 0, 0, 0.02),
      0px 5.2963px 6.51852px 0px rgba(0, 0, 0, 0.04),
      0px 13px 13px 0px rgba(0, 0, 0, 0.05),
      0px 25.03704px 25.48148px 0px rgba(0, 0, 0, 0.05),
      0px 42.12963px 46.85185px 0px rgba(0, 0, 0, 0.07),
      0px 65px 80px 0px rgba(0, 0, 0, 0.09);
  }
}
