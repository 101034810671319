@import '../scss/variables';

.root {
  background-color: rgba(250, 250, 248, 1);
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 16px;
  position: relative;
  margin: 0 0 88px;

  @media (min-width: $view-lg) {
    background-color: transparent;
    flex-direction: row;
    margin: 0;
    padding: 0;
    column-gap: 16px;
    position: relative;
  }
}
