@import '../scss/variables';

.navigation-menu {
  display: none;
  flex: 1 0 auto;
  overflow-y: scroll;

  @media (min-width: $view-lg) {
    display: block;
    padding-right: 24px;
    overflow-y: initial;
  }
}

.navigation-menu-nav {
  position: relative;

  @media (min-width: $view-lg) {
    overflow-y: initial;
    position: initial;
  }
}

.navigation-menu-title {
  left: -100rem;
  position: absolute;
}

.navigation-menu-active {
  display: block;
  background-color: var(--nav-mob-bg);
  height: 100%;
  min-height: calc(100vh - 64px);
  border-top: 1px solid var(--grey-mid);

  @media (min-width: $view-lg) {
    background-color: var(--white);
  }
}
