@import '../scss/variables';

.root {
  align-items: center;
  column-gap: 8px;
  display: none;
  list-style: none;
  height: 38px;
  padding: 0;

  @media (min-width: $view-lg) {
    display: flex;
  }
}
