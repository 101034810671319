@import '../scss/colours';
@import '../scss/helpers';
@import '../scss/variables';

.navigation-toggle {
  @include size(32px);
  line-height: 16px;
  position: relative;
  margin-left: 16px;

  @media (min-width: $view-lg) {
    display: none;
  }
}

.navigation-toggle-button {
  appearance: none;
  background-color: #f4f4f4;
  border: none;
  border-radius: 50%;
  box-sizing: border-box;
  color: currentColor;
  display: block;
  padding: 8px;
  transition: background-color 0.3s ease-in-out, border 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

  [data-visual-mode='dark'][data-direction='top'] & {
    background-color: rgba(255, 255, 255, 0.25);
  }
}

.navigation-toggle-button-hamburger {
  display: block;
  height: 16px;
  position: relative;
  width: 16px;
}

.navigation-toggle-button-hamburger-pickle,
.navigation-toggle-button-hamburger-cheese,
.navigation-toggle-button-hamburger-patty {
  background: currentColor;
  border-radius: 2px;
  display: block;
  height: 2px;
  position: absolute;
  width: 16px;
}

.navigation-toggle-button-hamburger-pickle {
  top: 2px;
  transition: top 0.3s ease-in-out 0.3s, transform 0.3s ease-in-out 0s;
}

.navigation-toggle-button-hamburger-cheese {
  top: 7px;
  transition: opacity 0s ease-in-out 0.4s;
}

.navigation-toggle-button-hamburger-patty {
  bottom: 2px;
  transition: bottom 0.3s ease-in-out 0.3s, transform 0.3s ease-in-out 0s;
}

.navigation-toggle-button-active {
  background-color: #f4f4f4;

  [data-visual-mode='dark'][data-direction='top'] & {
    background-color: #f4f4f4;
  }

  .navigation-toggle-button-hamburger-pickle {
    top: 7px;
    transform: rotate(-45deg);
    transition: top 0.3s ease-in-out 0s, transform 0.3s ease-in-out 0.3s;
  }

  .navigation-toggle-button-hamburger-cheese {
    opacity: 0;
    top: 7px;
    transition: opacity 0s ease-in-out 0.1s;
  }

  .navigation-toggle-button-hamburger-patty {
    bottom: 7px;
    transform: rotate(45deg);
    transition: bottom 0.3s ease-in-out 0s, transform 0.3s ease-in-out 0.3s;
  }
}

.root {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 16px;
  right: 16px;

  @media (min-width: $view-lg) {
    display: none;
  }
}

.navigation-button {
  transition: opacity 300ms ease-in-out;
}

.navigation-button-active {
  opacity: 0;
}

.navigation-button-inactive {
  opacity: 1;
}

.close-button {
  $backgroundColor: #e4e4e4;
  $crossColor: rgba(33, 2, 52, 1);

  /* Container */
  & .button {
    /* Styling */
    border: none;
    border-radius: 100%;
    transition: box-shadow 300ms ease-in-out;
    box-shadow: 0 0 4px 0 rgba($backgroundColor, 0.2);

    /* Positioning */
    display: flex;
    align-items: center;
    justify-content: center;

    /* Dimensions */
    padding: 0;
    width: 32px;
    height: 32px;
  }

  /* Hover state */
  .button:focus,
  .button:hover {
    box-shadow: 0 0 16px 0 rgba($backgroundColor, 1);
  }

  /* Icon Controls (Left X) */
  & .span {
    /* Dimensions */
    width: 16px;
    height: 2px;

    /* Positioning */
    display: block;
    position: absolute;
    transform: rotate(45deg);

    /* Colour */
    background: $crossColor;
  }

  /* Icon Controls (Right X) */
  .span + .span {
    background: $crossColor;
    transform: rotate(-45deg);
  }
}
