@import '../scss/variables';

.root {
  background-color: rgba(0, 0, 0, 0);
  bottom: 0;
  display: none;
  left: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  transition: background-color 300ms ease-in-out;
  top: 64px;
  user-select: none;
  z-index: 6;

  @media (min-width: $view-lg) {
    display: block;
  }
}

.active {
  background-color: rgba(0, 0, 0, 0.4);
}

.inactive {
  background-color: rgba(0, 0, 0, 0);
}
