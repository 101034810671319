@import '../scss/colours';
@import '../scss/variables';

.root {
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  &:first-child::after {
    border-bottom: 1px solid var(--grey-mid);
    bottom: 0;
    content: "";
    display: block;
    position: absolute;
    right: 0;
    left: 0;

    @media (min-width: $view-lg) {
      right: 24px;
      left: 24px;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: $view-lg) {
    border-radius: 8px;
    position: relative;
    background: none;
    border-radius: 0;
    box-shadow: none;
    margin: 0;

    &:hover,
    &.active {
      color: var(--bright-purple);

      .description {
        color: var(--deep-purple);
      }
    }
  }
}

.link {
  box-sizing: border-box;
  color: currentColor;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 8px 0;
  padding: 8px 0;
  text-decoration: none;
  width: 100%;
  position: relative;
  transition: color 300ms ease-in-out;

  &:hover {
    color: var(--bright-purple);
  }

  &:focus {
    outline: 5px auto Highlight;
    outline: 5px auto -webkit-focus-ring-color;
  }

  @media (min-width: $view-lg) {
    margin: 0;
    padding: 16px 24px;
  }
}

.title {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
}

.label {
  display: block;
  font-size: 26px;
  font-weight: 700;
  letter-spacing: -0.035em;
  line-height: 28px;
  width: 100%;

  @media (min-width: $view-lg) {
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 107.692% */
    letter-spacing: -0.91px;
  }
}

.description {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.015em;
  line-height: 18px;
}

.arrow {
  transform: translateX(-4px);
  transition: transform 300ms ease-in-out;

  .link:hover & {
    transform: translateX(0px);
  }
}
