@import '../scss/colours';
@import '../scss/helpers';
@import '../scss/variables';

.root {
  @media (min-width: $view-lg) {
    cursor: pointer;
    font-size: 20px;
    height: 38px;
  }
}

.link {
  background: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.045em;
  padding: 8px 0;
  position: relative;
  text-align: left;
  width: 100%;
  z-index: 1;
  margin-bottom: 8px;
  display: block;

  @media (min-width: $view-lg) {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.35px;
    line-height: 14px; /* 100% */
    margin: 0;
    padding: 12px 2px;
    transition: all 0.3s ease-in-out;
  }
}
