@import '../scss/variables';

.root {
  backdrop-filter: blur(32px);
  background-color: rgba(255, 255, 255, 0.7);
  border-bottom: 1px solid var(--grey-mid);
  bottom: unset;
  display: block;
  overflow: unset;
  position: fixed;
  top: 0;
  transform: translateY(0);
  transition: background-color 0ms ease-in-out, color 0ms ease-in-out, transform 300ms ease-in-out;
  width: 100%;
  will-change: background-color, color, transform;
  z-index: 2147483004;

  &.active {
    background-color: var(--white);
    border-bottom: 1px solid var(--grey-mid);
    color: var(--deep-purple);
    overflow: auto;
    bottom: 0;
  }

  &.contactOpen {
    color: var(--deep-purple) !important;
    background-color: var(--white) !important;
  }

  &.open {
    background-color: var(--white);
    color: var(--deep-purple);
  }

  &[data-visual-mode='dark'][data-direction='top'] {
    background-color: rgba(255, 255, 255, 0);
    border-bottom: 1px solid transparent;
    color: var(--white);
  }

  &[data-visual-mode='dark'][data-direction='top'].active {
    background-color: var(--white);
    color: var(--deep-purple);
  }

  &[data-direction='top'] {
    transform: translateY(0);
  }

  &[data-direction='down-close-to-top'] {
    transform: translateY(0);
  }

  &[data-direction='down'] {
    transform: translateY(-64px);
  }

  &[data-direction='up'] {
    transform: translateY(0);
  }
}

.active,
.inactive,
.open,
.closed {
  display: block;
}
