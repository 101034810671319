@import '../scss/colours';
@import '../scss/helpers';
@import '../scss/variables';

.root {
  padding: 0 16px;
  position: relative;

  @media (min-width: $view-lg) {
    padding: 32px 48px;
  }
}

.label {
  color: var(--bright-purple);
  display: none;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.025em;
  line-height: 18px;
  padding: 8px 0;
  position: relative;

  @media (min-width: $view-lg) {
    display: block;
    margin: 4px 0;
  }
}

.list {
  padding: 0;
}
