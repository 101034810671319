@import '../scss/variables';
@import '../scss/helpers';

.navigation-header-wrapper {
  width: 100%;
}

.navigation-header {
  box-shadow: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1440px;
  min-width: 320px;
  position: relative;
  width: 100%;
  height: 63px;
  border-bottom: 0;

  @media (min-width: $view-lg) {
    flex-direction: row;
  }
}
