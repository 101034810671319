@import '../styles/variables';
@import '../styles/helpers';

$view-lg: 72rem; // 1152px

.login-button {
  display: none;

  @media (min-width: $view-lg) {
    display: block;
  }
}

.collapse {
  position: absolute;
  background-color: white;
  width: 100vw;
  height: 468px;
  top: 63px;
  z-index: 4;
  padding: 0px 24px 34px;
}
