@import '../styles/variables';

.root {
  font-weight: 400;
  strong,
  b {
    font-weight: 600;
  }
}
.m {
  #{$headers} {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.015em;
  }
}

.s {
  #{$headers} {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.015em;
  }
}

.xs {
  #{$headers} {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
  }
  .card {
    #{$headers} {
      @media (min-width: $view-lg) {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.015em;
      }
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.015em;
    }
  }
}

.dark {
  #{$headers} {
    color: #fff;
  }
}

.light {
  #{$headers} {
    color: #210234;
  }
}

.margin-bottom-large {
  #{$headers} {
    margin-bottom: 24px;

    @media (min-width: $view-lg) {
      margin-bottom: 40px;
    }
  }
}

.margin-bottom-medium {
  #{$headers} {
    margin-bottom: 20px;
    @media (min-width: $view-lg) {
      margin-bottom: 32px;
    }
  }
}

.margin-bottom-small {
  #{$headers} {
    margin-bottom: 16px;
    @media (min-width: $view-lg) {
      margin-bottom: 24px;
    }
  }
}

.margin-bottom-tiny {
  #{$headers} {
    margin-bottom: 8px;
  }
}

.margin-bottom-none {
  #{$headers} {
    margin-bottom: 0;
  }
}

.mobile-left {
  #{$headers} {
    text-align: left;
  }
}

.mobile-center {
  #{$headers} {
    text-align: center;
  }
}

.mobile-right {
  #{$headers} {
    text-align: right;
  }
}

@media (min-width: $view-lg) {
  .desktop-left {
    #{$headers} {
      text-align: left;
    }
  }

  .desktop-center {
    #{$headers} {
      text-align: center;
    }
  }

  .desktop-right {
    #{$headers} {
      text-align: right;
    }
  }
}
