@mixin size($width, $height: $width) {
  height: $height;
  width: $width;
}

@mixin pseudo($display: block, $pos: absolute, $content: '') {
  content: $content;
  display: $display;
  position: $pos;
}

@mixin chevron($direction, $color: black, $position: absolute) {
  border: solid $color;
  border-width: 0 3px 3px 0;
  padding: 3px;
  @if $direction == right {
    transform: rotate(-45deg);
  } @else if $direction == up {
    transform: rotate(-135deg);
  } @else if $direction == down {
    transform: rotate(45deg);
  } @else if $direction == left {
    transform: rotate(-135deg);
  }
}

@mixin truncated($line-height, $lines) {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;
  max-height: $line-height * $lines;
  text-overflow: ellipsis;
}
