.root {
  margin: 0 auto;
  padding: 0 16px;
  overflow-x: visible;
  position:relative;
}

// Horizontal container spacing
.none {
  padding: 0px 0px;
}

// Full container
.full {
  max-width: 100%;
}

.large {
  max-width: 1280px;
}

// Medium container
.medium {
  max-width: 1024px;
}

// Small container
.small {
  max-width: 808px;
}