@import '../scss/colours';
@import '../scss/helpers';
@import '../scss/variables';

.menu-item-expandable {
  @media (min-width: $view-lg) {
    cursor: pointer;
    font-size: 14px;
    letter-spacing: 0.0225em;
    position: relative;
  }
}

.button {
  background-color: rgba(244, 244, 244, 1);
  border: 0;
  border-radius: 32px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.05), 0px 24px 60px 0px rgba(6, 47, 125, 0.05), 0px 2px 4px 0px rgba(7, 4, 146, 0.10);
  color: currentColor;
  cursor: pointer;
  display: block;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.4px;
  margin: 0;
  padding: 8px 16px;
  position: relative;
  transition: background-color 300ms ease-in-out, color 300ms ease-in-out;
  width: auto;
  z-index: 1;

  border-radius: 32px;

  background-color: rgba(244, 244, 244, 1);

  &:hover {
    background-color: rgba(235, 227, 255, 1);
  }

  &.active {
    background-color: #E5DAFF;
    color: var(--bright-purple);
  }

  @media (min-width: $view-lg) {
    [data-visual-mode='dark'][data-direction='top'] & {
      background: rgba(255, 255, 255, 0.25);
      color: inherit;

      &:hover {
        background: rgba(255, 255, 255, 0.4);
      }
    }

    [data-visual-mode='dark'][data-direction='top'] &.active {
      background-color: #E5DAFF;
      color: var(--bright-purple);
    }
  }
}

.collapse {
  color: var(--deep-purple);
  display: none;
  left: 0;
  min-height: calc(100vh - 80px);
  padding: 0;
  position: absolute;
  top: 0;
  top: 82px;
  width: 100%;
  z-index: 0;

  @media (min-width: $view-lg) {
    background-color: var(--white);
    border-top: 1px solid var(--grey-mid);
    box-shadow: 0px 1.2037px 3.14815px 0px rgba(0, 0, 0, 0.02),
      0px 5.2963px 6.51852px 0px rgba(0, 0, 0, 0.04),
      0px 13px 13px 0px rgba(0, 0, 0, 0.05),
      0px 25.03704px 25.48148px 0px rgba(0, 0, 0, 0.05),
      0px 42.12963px 46.85185px 0px rgba(0, 0, 0, 0.07),
      0px 65px 80px 0px rgba(0, 0, 0, 0.09);
    left: 0;
    min-height: auto;
    overflow: hidden;
    padding: 0;
    position: fixed;
    right: 0;
    top: 63px;
    width: 100%;
    z-index: 999;
  }

  .expanded & {
    display: block;
  }
}

.container {
  background-color: var(--nav-mob-bg);
  flex-direction: column;
  width: 100%;

  @media (min-width: $view-lg) {
    background-color: var(--white);
    display: flex;
    flex-direction: row;
    margin: auto;
    max-width: 1218px;
    position: relative;
    width: 100%;
    z-index: 2;
  }
}

.menu {
  box-sizing: border-box;
  width: 100%;

  @media (min-width: $view-lg) {
    width: 375px;
  }
}
