@import '../scss/variables';

.root {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  margin: 20px 16px;

  @media (min-width: $view-lg) {
    margin: 16px 0 16px 24px;
  }
}

.link {
  display: block;
}

.logo {
  display: block;
}
