@import '../styles/variables';

$view-lg: 72rem; // 1152px

.root {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;
  position: relative;
  background-color: transparent;
  color: white;
  border-radius: 4px;
  margin-left: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  transition: all 0.3s cubic-bezier(0.48, 0.23, 1, 1);
  width: 32px;
  height: 32px;

  @media (min-width: $view-lg) {
    width: 100%;
    height: fit-content;
    margin-left: 0px;
  }

  &.nav-mode {
    background-color: var(--nav-primary-button-bg-hover);
    color: var(--nav-primary-button-color);
    transition: all 0.3s ease-in-out;
    border: 1px solid transparent;

    &:hover {
      background-color: var(--nav-primary-button-bg-hover);
      color: var(--nav-primary-button-color-hover);
    }

    /* Home page - at the top */
    [data-visual-mode='dark'][data-direction='top'] &.nav-mode {
      background-color: transparent;
      border: 1px solid #ffffff;
      color: white;
    }
  }
}

.text {
  z-index: 2;
  margin-right: 8px;
  display: none;
  @media (min-width: $view-lg) {
    display: block;
  }
}

.icon {
  display: block;
  width: 18px;
  height: 18px;

  @media (min-width: $view-lg) {
    width: 14px;
    height: 14px;
  }
}

.small {
  height: 32px;
  padding: 0 13px;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.025em;
}
