@import '../scss/variables';

.root {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;

  @media (min-width: $view-lg) {
    align-items: center;
    flex-direction: row;
    height: 64px;
    justify-content: space-between;
    margin: 0;
    min-height: 100%;
    padding: 0;
    width: auto;
  }
}

.mobile-cta {
  background-color: var(--nav-mob-bg);
  bottom: 0;
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 24px 16px;
  position: relative;
  width: 100%;
  z-index: 2;

  @media (min-width: $view-lg) {
    display: none;
  }
}
