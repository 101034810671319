@import '../scss/colours';
@import '../scss/helpers';
@import '../scss/variables';

.root {
  padding: 0 16px;
  position: relative;

  @media (min-width: $view-lg) {
    padding: 32px 24px 48px;
  }
}

.label {
  color: var(--bright-purple);
  display: block;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.025em;
  line-height: 18px;
  padding: 8px 0;
  position: relative;

  @media (min-width: $view-lg) {
    padding: 8px 48px 8px 24px;
    margin: 4px 0;
  }
}

.list {
  padding: 0;
}

.arrow {
  position: absolute;
  right: 0px;
  transform: translateX(-4px);
  transition: transform 300ms ease-in-out;
  top: 10px;

  .label:hover & {
    transform: translateX(0px);
  }

  @media (min-width: $view-lg) {
    right: 24px;
  }
}
