@import '../styles/variables';

.root {
  width: 100%;
  font-weight: 500;
}
.xl {
  #{$headers} {
    font-size: 56px;
    line-height: 56px;
    letter-spacing: -0.045em;
    @media (min-width: $view-lg) {
      font-size: 64px;
      line-height: 64px;
    }
  }
}

.l {
  #{$headers} {
    font-size: 34px;
    line-height: 36px;
    letter-spacing: -0.045em;
    @media (min-width: $view-lg) {
      font-size: 40px;
      line-height: 40px;
    }
  }
}

.m {
  #{$headers} {
    font-size: 30px;
    line-height: 32px;
    letter-spacing: -0.035em;
    @media (min-width: $view-lg) {
      font-size: 30px;
      line-height: 32px;
    }
  }
}

.sm {
  #{$headers} {
    letter-spacing: -0.025em;
    font-size: 18px;
    line-height: 20px;
    @media (min-width: $view-lg) {
      font-size: 20px;
      line-height: 22px;
      letter-spacing: -0.035em;
    }
  }
}

.s {
  #{$headers} {
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.025em;
  }
}

.card {
  #{$headers} {
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.035em;
    @media (min-width: $view-lg) {
      font-size: 30px;
      line-height: 32px;
      letter-spacing: -0.035em;
    }
  }
}

// power up titles
.powerup {
  #{$headers} {
    font-size: 34px;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.045em;
    @media (min-width: $view-lg) {
      font-size: 40px;
      line-height: 48px;
      letter-spacing: -0.035em;
    }
  }
}

// power up titles
.banner {
  #{$headers} {
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;
    text-align: center;
    letter-spacing: -0.045em;

    @media (min-width: $view-lg) {
      font-size: 56px;
      line-height: 56px;
      letter-spacing: -0.035em;
    }
  }
  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    @media (min-width: $view-lg) {
      font-weight: 700;
      font-size: 30px;
      line-height: 32px;
    }
  }
}

.bold {
  #{$headers} {
    font-weight: 700;
  }
}

.dark {
  #{$headers} {
    color: #fff;
  }
}

.light {
  #{$headers} {
    color: #210234;
  }
}

.margin-bottom-large {
  #{$headers} {
    margin-bottom: 24px;
    @media (min-width: $view-lg) {
      margin-bottom: 40px;
    }
  }
}

.margin-bottom-medium {
  #{$headers} {
    margin-bottom: 20px;
    @media (min-width: $view-lg) {
      margin-bottom: 32px;
    }
  }
}

.margin-bottom-small {
  #{$headers} {
    margin-bottom: 16px;
    @media (min-width: $view-lg) {
      margin-bottom: 24px;
    }
  }
}

.margin-bottom-tiny {
  #{$headers} {
    margin-bottom: 8px;
  }
}

.margin-bottom-none {
  #{$headers} {
    margin-bottom: 0;
  }
}

.mobile-left {
  #{$headers} {
    text-align: left;
  }
}

.mobile-center {
  #{$headers} {
    text-align: center;
  }
}

.mobile-right {
  #{$headers} {
    text-align: right;
  }
}

@media (min-width: $view-lg) {
  .desktop-left {
    #{$headers} {
      text-align: left;
    }
  }

  .desktop-center {
    #{$headers} {
      text-align: center;
    }
  }

  .desktop-right {
    #{$headers} {
      text-align: right;
    }
  }
}

.disclaimer {
  #{$headers} {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.025em;
  }
}
