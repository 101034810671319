@import '../styles/variables';

$view-lg: 72rem; // 1152px

.root {
  align-items: center;
  background-color: var(--funnel);
  border-radius: 4px;
  border: none;
  color: var(--white);
  display: flex;
  flex-direction: row;
  font-style: normal;
  font-weight: 600;
  justify-content: center;
  line-height: 20px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s cubic-bezier(0.48, 0.23, 1, 1);
  width: fit-content;

  &:hover {
    background: var(--bright-purple);
    color: var(--white);

    .pink {
      left: -16px;
    }
  }
}

.text {
  z-index: 2;
}

.pink {
  background-color: var(--funnel-accent);
  display: none;
  height: 130px;
  left: calc(100% - 50px);
  position: absolute;
  top: -20px;
  transform: rotateZ(15deg);
  transition: all 0.3s ease-in;
  width: calc(100% + 32px);
  z-index: 1;

  @media (min-width: $view-lg) {
    display: block;
  }
}

.icon {
  display: inline-block;
  font-family: 'athena-icons';
  font-size: 20px;
  font-weight: normal;
  line-height: 20px;
  margin-left: 8px;
  right: 14px;
  text-rendering: optimizeLegibility;
  transform: translateZ(0);
  vertical-align: middle;
  white-space: nowrap;
  z-index: 2;
}

.large {
  flex: 1 1 50%;
  font-size: 14px;
  height: 40px;
  letter-spacing: -0.025em;
  padding: 0 13px;
}

.small {
  height: 32px;
  padding: 0 13px;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.025em;

  .icon {
    display: none;

    @media (min-width: $view-lg) {
      display: block;
    }
  }

  .pink {
    left: calc(100% - 42px);
  }
}
