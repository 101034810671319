@import '../scss/colours';
@import '../scss/helpers';
@import '../scss/variables';

// HeaderListItemButtonExpandableDesktop
// display none min width
// HeaderListItemButtonExpandableMobile > ContactContent
// display none min width

// pass state down and change menu based off that

.menu-item-expandable {
  list-style: none;
  position: relative;
}

.button {
  // appearance: none;
  background-color: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  display: flex;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.045em;
  line-height: 24px;
  margin: 0;
  position: relative;
  text-align: left;
  transition: color 300ms ease-in-out;
  width: 100%;
  z-index: 1;
  padding: 0px;

  @media (min-width: $view-lg) {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.35px;
    line-height: 14px; /* 100% */
    transition: all 0.3s ease-in-out;

    [data-visual-mode='dark'][data-direction='top'] .expanded & {
      color: var(--white);
    }
  }
}

.collapse {
  background-color: var(--white);
  display: none;
  padding: 0;
  position: absolute;

  top: 50px;
  right: 0;
  width: 100%;
  z-index: 3;

  @media (min-width: $view-lg) {
    top: 48px;
    left: auto;
  }

  .expanded & {
    display: block;
    width: 480px;
    padding: 0;
    // left: 0;
    padding: 0;
  }
}

.menu {
  border-top: 1px solid var(--grey-mid);
  display: flex;
  padding: 0;
  flex-direction: column;
  box-shadow: 0px 1.2037px 3.14815px 0px rgba(0, 0, 0, 0.02), 0px 5.2963px 6.51852px 0px rgba(0, 0, 0, 0.04),
    0px 13px 13px 0px rgba(0, 0, 0, 0.05), 0px 25.03704px 25.48148px 0px rgba(0, 0, 0, 0.05),
    0px 42.12963px 46.85185px 0px rgba(0, 0, 0, 0.07), 0px 65px 80px 0px rgba(0, 0, 0, 0.09);
}
