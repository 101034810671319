@import '../scss/colours';
@import '../scss/variables';

.root {
  list-style: none;
  padding: 8px 0;

  @media (min-width: $view-lg) {
    padding: 10px 0;
  }
}

.root + .root {
  @media (min-width: $view-lg) {
    border-top: 1px solid var(--grey-mid);
  }
}

.link {
  color: var(--deep-purple);
  cursor: pointer;
  display: block;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.025em;
  line-height: 18px;
  padding: 6px 0;
  transition: color 300ms ease-in-out;

  &:hover {
    color: var(--bright-purple);
  }

  > .arrow {
    display: none;
  }

  @media (min-width: $view-lg) {
    font-size: 26px;
    font-weight: 700;
    letter-spacing: -0.035em;
    line-height: 28px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    > .arrow {
      display: block;
    }
  }
}

.arrow {
  transform: translateX(-4px);
  transition: transform 300ms ease-in-out;

  .link:hover & {
    transform: translateX(0px);
  }
}
