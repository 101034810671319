@import '../scss/colours';
@import '../scss/helpers';
@import '../scss/variables';

.contact-details {
  color: $primary;
  position: relative;
  max-width: 375px;
  margin: auto;

  .title {
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: -0.025em;
    text-align: left;
    margin-left: 8px;
  }

  @media (min-width: $view-lg) {
    width: 100%;
    max-width: unset;
    padding: 0 48px 40px;
  }
}

.contact-details-list {
  list-style: none;
  padding: 0;
}

.call-me-maybe-button-group {
  display: flex;
  flex-direction: row;
  gap: 16px;

  padding: 0;
  padding-bottom: 10px;
}

.contact-item {
  border-bottom: 1px solid #e3e3e3;
  min-height: 40px;
  margin: 0;
  display: grid;
  grid-template: 1fr / 28px 100px 1fr;
  align-items: center;
  height: 52px;
  &:nth-child(4) {
    border-bottom: 0;
  }

  @media (min-width: $view-lg) {
    padding: 0;
    margin: 0;
  }
}

.contact-item-opening-hours {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin-top: 28px;
}

.contact-time-and-day {
  display: flex;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: -0.025em;
  text-align: center;
  color: var(--deep-purple);

  margin-bottom: 8px;

  span {
    margin-right: 32px;
  }

  @media (min-width: $view-lg) {
    justify-content: space-between;

    span {
      margin-right: 0;
    }
  }
}

.highlight {
  color: var(--bright-purple);
  display: block;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.025em;
  text-decoration: none;
  margin-bottom: 0;
  appearance: none;
  background-color: white;
  border: none;
  padding: 0;
}

.label {
  color: #000000;
  display: block;
  padding: 32px 0px 28px;

  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.015em;
  text-align: left;
}

.icon {
  height: 20px;
  width: 20px;
}

.book-call-button {
  color: var(--deep-purple);
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  transition: all 0.3s cubic-bezier(0.48, 0.23, 1, 1);
  width: fit-content;
  background: transparent;

  border: 1px solid var(--deep-purple);
  padding: 10px;
  height: 40px;

  .icon {
    display: inline-block;
    font-family: 'athena-icons';
    font-size: 20px;
    font-weight: normal;
    line-height: 20px;
    margin-left: 8px;
    right: 14px;
    text-rendering: optimizeLegibility;
    transform: translateZ(0);
    vertical-align: middle;
    white-space: nowrap;
    z-index: 2;
  }

  &.dark {
    border: 1px solid var(--purple-tint);
  }

  &:hover {
    border: 1px solid var(--bright-purple);
    color: var(--bright-purple);

    &.dark {
      border: 1px solid var(--white);
      color: var(--white);
    }
  }
}

.call-me-now-button {
  color: var(--white);
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  transition: all 0.3s cubic-bezier(0.48, 0.23, 1, 1);
  width: fit-content;
  background-color: var(--deep-purple);

  border: 1px solid var(--deep-purple);
  padding: 10px;
  height: 40px;

  svg {
    margin-left: 8px;
    right: 14px;
  }

  &.dark {
    border: 1px solid var(--purple-tint);
  }

  &:hover {
    border: 1px solid var(--bright-purple);
    background-color: var(--bright-purple);

    &.dark {
      border: 1px solid var(--white);
      color: var(--white);
    }
  }
}
