@import '../styles/variables';

.root {
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-direction: row;
  transition: all 0.3s cubic-bezier(0.48, 0.23, 1, 1);
  width: fit-content;

  &.icon-mode {
    position: relative;
    height: 40px;
    width: 40px;
    padding: 0;
    border-radius: 50%;
    padding-left: 3px;

    &.small {
      height: 32px;
      width: 32px;
      border-radius: 50%;
      padding-left: 0;
    }

    &.icon {
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
    }
  }
 
  &.nav-mode {
    background-color: var(--nav-primary-button-bg);
    color: var(--nav-primary-button-color);
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: var(--nav-primary-button-bg-hover);
      color: var(--nav-primary-button-color-hover);
    }

    [data-visual-mode='dark'][data-direction='top'] &.nav-mode {
      background-color: var(--nav-primary-button-color);
      color: var(--nav-primary-button-bg);
    }
  }
}

.icon {
  font-family: 'athena-icons';
  font-weight: normal;
  width: 34px;
  text-rendering: optimizeLegibility;
  white-space: nowrap;
  transform: translateZ(0);
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

.small-icon {
  font-size: 20px;
  line-height: 20px;
  font-weight: normal;
}

.large-icon {
  font-size: 24px;
  line-height: 24px;
  width: 24px;
}

.large {
  height: 40px;
  padding: 0 13px;
  font-size: 14px;
  letter-spacing: -0.025em;
}

.small {
  height: 32px;
  padding: 0 9px;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.025em;
}

.light {
  color: var(--deep-purple);
}

.dark {
  color: var(--white);
}

.solid {
  border: none;
  background: var(--deep-purple);
  color: var(--white);
 
  &.dark {
    background-color: var(--white);
    color: var(--deep-purple);
  }
 
  &:hover {
    background: var(--bright-purple);
    color: var(--white);
 
    &.dark {
      background: var(--purple-light);
      color: var(--deep-purple);
    }
  }
}

.outline {
  border: none;
  background: transparent;
  border: 1px solid var(--purple-tint-50);

  &.dark {
    border: 1px solid var(--purple-tint);
  }

  &:hover {
    border: 1px solid var(--bright-purple);
    color: var(--bright-purple);

    &.dark {
      border: 1px solid var(--white);
      color: var(--white);
    }
  }
}

.borderless {
  border: none;
  background: transparent;

  &.dark {
    color: var(--purple-light);
  }

  &:hover {
    color: var(--bright-purple);

    &.dark {
      color: var(--white);
    }
  }
}
