@import '../scss/variables';

.root {
  @media (min-width: $view-lg) {
    display: none;
  }
}

.list {
  background-color: var(--white);
  backdrop-filter: blur(16px);
  background-color: rgba(255, 255, 255, 0.35);
  border-top: 1px solid var(--grey-mid);
  bottom: 0;
  display: flex;
  gap: 16px;
  padding: 16px;
  position: fixed;
  width: 100%;
  z-index: 2;
}
