@import '../scss/colours';
@import '../scss/variables';

.accessibility-links {
  display: block;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 1000;
}

.accessibility-links-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.accessibility-links-list-item {
  margin: 0;
  padding: 0;
}

.accessibility-links-title {
  clip: rect(0.0625rem, 0.0625rem, 0.0625rem, 0.0625rem);
  color: var(--bright-purple);
  display: block;
  height: 0.0625rem;
  overflow: hidden;
  position: absolute;
  right: 0;
  white-space: nowrap;
  width: 0.0625rem;
  z-index: 1;
}

.accessibility-link {
  background: var(--white);
  border-radius: 0.125rem;
  clip: rect(0.0625rem, 0.0625rem, 0.0625rem, 0.0625rem);
  color: var(--bright-purple);
  display: block;
  font-size: 1rem;
  font-weight: 500;
  height: 0.0625rem;
  left: 160px;
  line-height: 1.25rem;
  margin: 0;
  overflow: hidden;
  padding: 0.625rem 1rem;
  position: absolute;
  text-decoration: none;
  top: -52px;
  white-space: nowrap;
  width: 0.0625rem;
  z-index: 1;

  @media (min-width: $view-lg) {
    left: 16px;
    top: 80px;
  }

  &:focus {
    clip: auto;
    height: auto;
    overflow: hidden;
    position: absolute;
    width: auto;
  }
}
